<template>
  <div>
    <b-row>
      <!-- <b-col>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="isTaskHandlerSidebarActive = !isTaskHandlerSidebarActive"
        >
          Create Task
        </b-button>
      </b-col> -->
      <b-col>
        <p>Select Date :-</p>
        <flat-pickr
          v-model="filterDate"
          @input="onFilterSelected"
          class="form-control"
        />
      </b-col>
      <b-col>
        <p>Select User :-</p>
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="profiles"
          v-model="filterProfile"
          label="email"
          id="profile"
          class="profile-selector"
          :reduce="(option) => option.id"
          @input="onFilterSelected"
        >
          <template #option="{ avatar_url, email }">
            <b-avatar size="26" :src="avatar_url" />
            <span class="ml-50 d-inline-block align-middle"> {{ email }}</span>
          </template>

          <template #selected-option="{ avatar_url, email }">
            <b-avatar size="26" :src="avatar_url" />

            <span class="ml-50 d-inline-block align-middle"> {{ email }}</span>
          </template>
        </v-select>
      </b-col>
    </b-row>
    <br />
    <br />
    <b-table :items="tasks_items" :fields="fields" striped responsive>
      <template #cell(expand)="row">
        <b-form-checkbox
          v-model="row.detailsShowing"
          plain
          class="vs-checkbox-con"
          @change="row.toggleDetails"
        >
          <span class="vs-checkbox">
            <span class="vs-checkbox--check">
              <i class="vs-icon feather icon-check" />
            </span>
          </span>
          <span class="vs-label">{{
            row.detailsShowing ? "Hide" : "Show"
          }}</span>
        </b-form-checkbox>
        <!-- {{ row.item }} -->
      </template>

      <template #row-details="row">
        <b-card>
          <b-row class="mb-2">
            <b-col md="4" class="mb-1">
              <strong>Day Start Tasks : </strong>
              <div v-html="row.item.day_start_tasks"></div>
            </b-col>
            <b-col md="4" class="mb-1">
              <strong>Day End Tasks : </strong>
              <div v-html="row.item.day_end_tasks"></div>
            </b-col>
            <b-col md="4" class="mb-1">
              <strong>Issues : </strong>
              <div v-html="row.item.issues"></div>
            </b-col>
            <b-col md="4" class="mb-1">
              <strong>Log out time : </strong>
              <div v-html="row.item.log_out_time"></div>
            </b-col>
          </b-row>

          <b-button
            size="sm"
            variant="outline-secondary"
            @click="row.toggleDetails"
          >
            Hide Details
          </b-button>
        </b-card>
      </template>

      <!-- <template #cell(edit)="row">
        <i
          style="cursor: pointer"
          class="vs-icon feather icon-edit"
          @click="
            () => {
              task = row.item;
              isTaskHandlerSidebarActive = !isTaskHandlerSidebarActive;
            }
          "
        />
      </template>
      <template #cell(delete)="row">
        <i
          style="cursor: pointer"
          class="vs-icon feather icon-delete"
          @click="removeTask(row.item.id)"
        />
      </template> -->
      <!-- <template #cell(avatar)="data">
        <b-avatar :src="data.value" />
      </template> -->

      <!-- <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}ff
        </b-badge>
      </template> -->
    </b-table>
    <!-- Task Handler -->
    <!-- <todo-task-handler-sidebar
      v-model="isTaskHandlerSidebarActive"
      :task="task"
      :clear-task-data="clearTaskData"
      @remove-task="removeTask"
      @add-task="addTask"
      @update-task="updateTask"
    /> -->
  </div>
</template>

<script>
import { supabase } from "@/supabase";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import { ref, watch, computed, onUnmounted } from "@vue/composition-api";
import TodoTaskHandlerSidebar from "./TodoTaskHandlerSidebar.vue";
import store from "@/store";
import taskStoreModule from "./taskStoreModule";

import {
  BTable,
  BFormCheckbox,
  BButton,
  BCard,
  BRow,
  BCol,
  BAvatar,
  BBadge,
} from "bootstrap-vue";

import vSelect from "vue-select";

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    TodoTaskHandlerSidebar,
    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        "expand",
        "project_name",
        "date",
        "check_in_time",
        // "edit",
        // "delete",
      ],
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
    };
  },
  // mounted: async function () {
  //   const { data, error } = await supabase.from("daily-task").select();
  //   console.log("in mounted", data);
  //   this.items = data;
  // },
  setup() {
    const isTaskHandlerSidebarActive = ref(false);
    const tasks_items = ref([]);

    const ADMIN_TASKS_STORE_MODULE_NAME = "admin-tasks";

    if (!store.hasModule(ADMIN_TASKS_STORE_MODULE_NAME))
      store.registerModule(ADMIN_TASKS_STORE_MODULE_NAME, taskStoreModule);

    onUnmounted(() => {
      if (store.hasModule(ADMIN_TASKS_STORE_MODULE_NAME))
        store.unregisterModule(ADMIN_TASKS_STORE_MODULE_NAME);
    });

    const blankTask = {
      id: null,
      project_name: "",
      check_in_time: null,
      log_out_time: null,
      issues: "",
      day_start_tasks: "",
      day_end_tasks: "",
      date: new Date(),
      assignee: null,
      is_completed: false,
      is_deleted: false,
      is_important: false,
    };
    const task = ref(JSON.parse(JSON.stringify(blankTask)));
    const clearTaskData = () => {
      task.value = JSON.parse(JSON.stringify(blankTask));
    };

    const profiles = ref([]);

    supabase
      .from("profiles")
      .select()
      .then(({ data, error }) => {
        if (error) {
          console.log("Error getting profiles ", error);
          return;
        }
        profiles.value = data;
      });

    const filterDate = ref(null);
    const filterProfile = ref(null);

    const onFilterSelected = (val) => {
      fetchTasks({
        date: filterDate.value,
        user_id: filterProfile.value,
      });
    };

    const fetchTasks = (filters = {}) => {
      store.dispatch("admin-tasks/fetchTasks", filters).then((val) => {
        tasks_items.value = val;
      });
    };

    fetchTasks();

    // const addTask = (val) => {
    //   store.dispatch("tasks/addTask", val).then(() => {
    //     fetchTasks();
    //   });
    // };
    // const removeTask = (id) => {
    //   store.dispatch("tasks/removeTask", { id }).then(() => {
    //     fetchTasks();
    //   });
    // };
    // const updateTask = (taskData) => {
    //   console.log("in update ", taskData);
    //   store.dispatch("tasks/updateTask", { task: taskData }).then(() => {
    //     fetchTasks();
    //   });
    // };
    return {
      profiles,
      filterDate,
      filterProfile,
      onFilterSelected,
      tasks_items,
      // task,
      // removeTask,
      // addTask,
      // updateTask,
      // clearTaskData,
      isTaskHandlerSidebarActive,
    };
  },
};
</script>
